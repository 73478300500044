.step-advice-content {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.step-advice-content span {
    display: inline-block;
    animation: marquee 8s linear infinite;  /* 10秒是滚动的持续时间，可以根据需要调整 */
    /*颜色为灰色*/
    color: blue;
}

@keyframes marquee {
    /* 动画开始时 (0%进度) */
    0% {
        /* 将文本向右移动，使其完全在容器外，此时文本从右侧开始进入 */
        transform: translateX(-30%);
    }
    /* 动画进行到一半时 (50%进度) */
    50% {
        /* 将文本向左移动，使其完全在容器外，此时文本已经完全滚动到容器的左侧 */
        transform: translateX(0%);
    }
    /* 动画结束时 (100%进度) */
    100% {
        /* 将文本再次向右移动，返回到初始状态，此时文本再次从右侧进入 */
        transform: translateX(-30%);
    }
}


